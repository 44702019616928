<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row>
      <v-col>
        <h3>
          Cambiar acceso mensual
        </h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-password-field
          v-model="password"
          label="Ingrese la contraseña de acceso"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-password-field
          v-model="passwordRepeat"
          label="Repite la contraseña"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-autocomplete
          v-model="selectedDay"
          label="Selecciona el dia de renovación"
          type="text"
          :items="days"
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <span
          v-for="(error, i) in errors"
          :key="error-i"
          class="error--text"
        >
          {{ error }}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          color="primary"
          @click="handleSave"
        >
          Guardar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Swal from 'sweetalert2'

  export default {
    data () {
      return {
        password: null,
        id: null,
        selectedDay: null,
        passwordRepeat: null,
        errors: [],
        days: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 21, 21, 24, 25, 26, 27, 28],
      }
    },
    async mounted () {
      await this.handleGet()
    },
    methods: {

      async handleGet () {
        const response = await this.$api.Access.get()
        if (response.status === 200) {
          this.password = response.data[0].password_access
          this.selectedDay = response.data[0].expiration_date
          this.id = response.data[0].id
        }
      },

      async handleSave () {
        this.errors.splice(0, this.errors.length)
        if (!this.password || !this.passwordRepeat || !this.selectedDay) {
          this.errors.push('Complete todos los campos')
        } else if (this.password !== this.passwordRepeat) {
          this.errors.push('Las contraseñas no coiciden')
        } else {
          try {
            const payload = {
              password_access: this.password,
              expiration_date: this.selectedDay,
            }
            const response = await this.$api.Access.onSave(payload)
            if (response.status === 204) {
              const updated = await this.$api.Access.update(payload, this.id)
              if (updated.status === 200) {
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Se actualizó el acceso!',
                  showConfirmButton: false,
                  timer: 1500,
                })
              }
            } else if (response.status === 200) {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Se creó el acceso!',
                showConfirmButton: false,
                timer: 1500,
              })
            }
          } catch (error) {
            this.errors.push('Ha ocurrido un error en el servidor')
          }
        }
      },
    },
  }
</script>
